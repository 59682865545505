import React from 'react';
import { Attachment, Case, CaseRelation, CaseType } from '../../api/caseApiTypes';
import { PhotoCaseDetail } from './forms/PhotoCaseDetail';
import { RetouchCaseDetail } from './forms/RetouchCaseDetail';
import { GenericCaseDetail } from './forms/GenericCaseDetail';
import { SingleProductCaseDetail } from './forms/SingleProductCaseDetail';
import { DiningSetCaseDetail } from './forms/DiningSetCaseDetail';
import { VideoCaseDetail } from './forms/VideoCaseDetail';

export interface CaseTypeDetailComponentFunctions {
  onDeleteCase: () => void;
  onDeleteAttachment: (attachment: Attachment) => void;
  onDeleteRelation: (relation: CaseRelation) => void;
  onCasePartialChange: (partial: Partial<Case>) => void;
  onCreateRelatedCase: (caze: Case) => void;
}

export interface CaseTypeDetailComponentProps extends CaseTypeDetailComponentFunctions {
  isNewCase: boolean;
  readonly?: boolean;
}

interface Props {
  caze: Case;
  subComponentProps: CaseTypeDetailComponentProps;
}

export const CaseTypeDetail: React.FC<Props> = ({ caze, subComponentProps }) => {
  switch (caze?.type) {
    case CaseType.ContextualPhoto:
    case CaseType.InstallationPhoto:
      return <PhotoCaseDetail caze={caze} {...subComponentProps} />;
    case CaseType.Retouch:
      return <RetouchCaseDetail caze={caze} {...subComponentProps} />;
    case CaseType.SingleProduct:
      return <SingleProductCaseDetail caze={caze} maxArticles={1} {...subComponentProps} />;
    case CaseType.ProductContextual:
      return <SingleProductCaseDetail caze={caze} {...subComponentProps} />;
    case CaseType.DiningSet:
      return <DiningSetCaseDetail caze={caze} {...subComponentProps} />;
    case CaseType.Video:
      return <VideoCaseDetail caze={caze} {...subComponentProps} />;
    default:
      return <GenericCaseDetail caze={caze} {...subComponentProps} />;
  }
};
