import { CaseType, StateEnum, States } from '../../api/caseApiTypes';
import { FilterableCase } from '../../api/filterTypes';
import { SearchContext } from '../../hooks/useSearchState';
import { CasePropClauseResolverConfig } from '../sidebar/filter/propClauseResolver/resolverConfigs';
import { SearchFilterTranslator } from '../sidebar/filter/searchFilterTranslator';
import { CaseKanbanBoard } from './CaseKanbanBoard';
import { LaneDefinitions } from './LaneDefinitions';

export const KanbanSupportedTypes = [CaseType.SingleProduct, CaseType.ProductCollection, CaseType.DiningSet];

const translator = new SearchFilterTranslator<FilterableCase>(CasePropClauseResolverConfig);

export const KanbanFilters = {
  userFilters: {
    type: { value: KanbanSupportedTypes.map(state => state.toString()) },
    Case_CaseState: {
      value: States.AllExcept([StateEnum.none, StateEnum.incoming, StateEnum.closed, StateEnum.deleted]).map(state => state.toString()),
    },
  },
};

const filterTree = translator.getClauses(KanbanFilters);

export const CaseKanbanPlan = () =>
  CaseKanbanBoard({ filters: filterTree, lanes: LaneDefinitions, searchContext: SearchContext.PlanningKanban });
