import { useEffect, useState } from 'react';
import { Right } from '../../api/userApi';
import { useAppDispatch } from '../../hooks/useAppRedux';
import { useRights, useRightsFn } from '../../hooks/useRights';
import { LocalStorageKeys, SessionStorageKeys, useLocalStorage, useSessionStorage } from '../../hooks/useStorage';
import { Grid, Box, CircularProgress, Paper } from '@mui/material';
import {
  DraggableLocation,
  DropResult,
  ResponderProvided,
  Draggable,
  Droppable,
  DroppableProps,
  DragDropContext,
} from 'react-beautiful-dnd';
import { Case } from '../../api/caseApiTypes';
import { SearchQuery } from '../../commonComponents/SearchQuery';
import { useCaseSearchState, SearchContext } from '../../hooks/useSearchState';
import { CaseKanbanCard } from './CaseKanbanCard';
import { CaseKanbanLane } from './CaseKanbanLane';
import { KanbanAction, KanbanLane } from './LaneDefinitions';
import { ByPriority, CalculatePriority } from './PriorityUtils';

import styles from './CaseKanbanBoard.module.css';
import { PredicateClause } from '../../api/searchApiTypes';
import { FilterableCase } from '../../api/filterTypes';
import { search } from '../cases/casesSlice';

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

const DefaultLaneState: Record<string, boolean> = {};

export type Props = {
  filters: PredicateClause<FilterableCase>;
  lanes: readonly KanbanLane[];
  searchContext: SearchContext;
};

export function CaseKanbanBoard({ filters, lanes, searchContext }: Props) {
  const canWrite = useRights(Right.WriteCase);
  const isAdmin = useRights(Right.Administration);
  const getRight = useRightsFn();
  const dispatch = useAppDispatch();

  const [query, setQuery] = useSessionStorage(SessionStorageKeys.searchQuery, '');

  const [laneState, setLaneState] = useLocalStorage(LocalStorageKeys.kanbanLaneState, DefaultLaneState);
  const toggleLaneState = (lane: string) => {
    const newLaneState = { ...laneState };
    newLaneState[lane] = !newLaneState[lane];
    setLaneState(newLaneState);
  };

  const [casesByLaneIndex, setCasesByLaneIndex] = useState<Record<number, Case[]>>({});
  const [continuation, setContinuation] = useState(0);

  const { items, count, isSearching } = useCaseSearchState(searchContext);

  useEffect(() => {
    dispatch(
      search({
        query,
        orderBy: [{ id: 'photoAndRetouchDeadline', desc: false }],
        filterTree: filters,
        continuation,
        pageSize: 250,
        searchContext: searchContext,
      }),
    );
  }, [dispatch, query, continuation, filters, searchContext]);

  // Keep fetching until all entries are loaded.
  useEffect(() => {
    if (items.length < count) {
      setContinuation(items.length);
    }
  }, [items, count]);

  useEffect(() => {
    setContinuation(0);
  }, [query]);

  useEffect(() => {
    if (items == null) {
      setCasesByLaneIndex({});
    } else {
      setCasesByLaneIndex(
        lanes.reduce((record: Record<number, Case[]>, lane, index) => {
          record[index] = (items ?? []).filter(lane.filter).sort(ByPriority);
          return record;
        }, {}),
      );
    }
  }, [items, lanes]);

  const updateLocalState = (oldLaneIndex: number, newLaneIndex: number, caze: Case, priority?: number) => {
    const caseIndex = casesByLaneIndex[oldLaneIndex].findIndex(c => c === caze);

    casesByLaneIndex[oldLaneIndex].splice(caseIndex, 1);

    const resultingCase = priority != null ? { ...caze, priority } : caze;

    const newLane = casesByLaneIndex[newLaneIndex];
    newLane.push(resultingCase);
    casesByLaneIndex[newLaneIndex] = newLane.sort(ByPriority);

    setCasesByLaneIndex(casesByLaneIndex);
  };

  const updateState = async (
    caze: Case,
    apiAction: KanbanAction,
    oldLaneIndex: number,
    oldLanePos: number,
    newLaneIndex: number,
    newLanePos: number,
  ) => {
    const priority = CalculatePriority(caze, casesByLaneIndex[newLaneIndex], newLanePos, oldLanePos, oldLaneIndex === newLaneIndex);

    await updateLocalState(oldLaneIndex, newLaneIndex, caze, priority);
    await dispatch(apiAction(caze, priority));
  };

  const droppedInPlace = (source: DraggableLocation, destination: DraggableLocation) => {
    return source.droppableId === destination.droppableId && source.index === destination.index;
  };

  const onDragEnd = async ({ draggableId, source, destination }: DropResult, _: ResponderProvided) => {
    // Don't do anything if dropped outside or on same position
    if (destination == null || droppedInPlace(source, destination)) {
      return;
    }

    const oldLaneIndex = Number(source.droppableId);
    const newLaneIndex = Number(destination.droppableId);

    const caze = items.find(c => c.id === draggableId);

    const apiAction = lanes[newLaneIndex].action;

    if (caze == null || apiAction == null) {
      return;
    }

    await updateState(caze, apiAction, oldLaneIndex, source.index, newLaneIndex, destination.index);
  };

  const getLaneCards = (cases: Case[]) =>
    cases.map((caze, index) => (
      <Draggable key={caze.id} draggableId={caze.id} index={index} isDragDisabled={!canWrite}>
        {provided => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <CaseKanbanCard caze={caze} />
          </div>
        )}
      </Draggable>
    ));

  const getLane = (lane: KanbanLane, index: number) => {
    const hasRight = canWrite && (lane.right == null || getRight(lane.right));
    const hasAction = lane.action != null;
    const droppable = hasAction && (isAdmin || hasRight);
    const collapsed = laneState[lane.title];
    const onToggleCollapsed = () => toggleLaneState(lane.title);
    const cases = casesByLaneIndex[index] ?? [];
    const noCases = cases.length === 0;

    return (
      <Grid item key={lane.title} className={styles.lane}>
        <CaseKanbanLane title={lane.title} collapsed={collapsed} onToggleCollapsed={onToggleCollapsed}>
          <StrictModeDroppable droppableId={index.toString()} isDropDisabled={!droppable}>
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef} className={styles.dropzone}>
                {isSearching && noCases && (
                  <Box display="flex" flexDirection="row" justifyContent="center">
                    <CircularProgress />
                  </Box>
                )}
                {getLaneCards(cases)}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </CaseKanbanLane>
      </Grid>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <Paper className={styles.searchPanel} elevation={0}>
          <SearchQuery onSearch={value => setQuery(value)} isSearching={isSearching} />
        </Paper>
        <div className={styles.boardContainer}>
          <Grid container wrap="nowrap" spacing={2}>
            <DragDropContext onDragEnd={onDragEnd}>{lanes.map(getLane)}</DragDropContext>
          </Grid>
        </div>
      </div>
    </>
  );
}
