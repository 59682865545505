import React, { useEffect, useRef, useState } from 'react';

import { Case, Image } from '../../../api/caseApiTypes';
import { UploadProgressHandlerForFile } from '../../../api/commonApiTypes';
import { Right } from '../../../api/userApi';
import { DropzoneCallback, DropzoneWithProgress } from '../../../commonComponents/DropzoneWithProgress';
import { MediaCard } from '../../../commonComponents/MediaCard';
import { GetThumbnailUri, HasThumbnail, ThumbnailImageSize } from '../../../helpers/DerivedImageHelpers';
import { useRights } from '../../../hooks/useRights';
import { ReplaceMediaAction } from '../../cases/caseDetailSlice';
import { ImageCardDownload } from '../ImageCardDownload';
import { DropFileActionDialog } from './DropFileActionDialog';
import { MediaWithPlayIcon } from './MediaWithPlayIcon';
import { ImageCardHeader } from './ImageCardHeader';
import { ImageCardBody } from './ImageCardBody';
import { RemoveImageButton } from './RemoveImageButton';
import { ImageCardFooter } from './ImageCardFooter';
import { ImageCardZoomButton } from './ImageCardZoomButton';
import { useAppDispatch, useAppSelector } from '../../../hooks/useAppRedux';
import { ensureImagesLoaded, isImageSelected, setSelectedMedia, toggleSelectedMedia, zoomImage } from '../../images/imagesSlice';
import { useTheme } from '@mui/material';
import { useSelectionContext } from '../../images/selectedImagesContext/selectionContextContext';

export interface AdditionalAction {
  icon: JSX.Element;
  action: () => void;
  tooltip: string;
  disabled?: boolean;
}
interface Props {
  className?: string;
  image?: Image;
  caze?: Case;
  onClick?: () => void;
  onRemove?: () => void;
  onImageActiveVersionChange?: (imageId: string, activeVersion: string) => void;
  disableInteraction?: boolean;
  disableRemoval?: boolean;
  additionalActions?: AdditionalAction[];
  raised?: boolean;
  readonly?: boolean;
  replaceMediaAction?: ReplaceMediaAction;
  enableReplacement?: boolean;
  enableVariantCreation?: boolean;
  displayedVersion?: string;
  style?: React.CSSProperties;
  fileUploadInProgress?: boolean;
  enlargeable?: boolean;
}

const PlaceholderUri = '/placeholder320.svg';

export const ImageCard: React.FC<Props> = ({
  className,
  image,
  caze,
  onClick,
  onRemove,
  onImageActiveVersionChange,
  additionalActions,
  disableInteraction,
  disableRemoval,
  raised,
  readonly,
  enableReplacement,
  enableVariantCreation,
  displayedVersion,
  style,
  fileUploadInProgress,
  replaceMediaAction,
  enlargeable,
}) => {
  const dispatch = useAppDispatch();

  const [files, setFiles] = useState<File[]>([]);
  const [onUploadProgress, setOnUploadProgress] = useState<{ handler?: UploadProgressHandlerForFile }>({});
  const [dropFileActionDialogVisible, setDropFileActionDialogVisible] = useState(false);
  const canUploadImage = useRights(Right.ManageImage);
  const cardRef = useRef<HTMLDivElement>(null);

  const canCreateVariant = enableVariantCreation && canUploadImage;
  const canReplaceImage = enableReplacement && canUploadImage;

  displayedVersion = displayedVersion ?? image?.activeVersion;

  const [enlarged, setEnlarged] = useState(false);

  useEffect(() => {
    if (!enlargeable) {
      setEnlarged(false);
    }
  }, [enlargeable]);

  const { palette } = useTheme();

  const selectionContext = useSelectionContext();
  const isSelected = useAppSelector(isImageSelected(selectionContext, image?.id || ''));
  const toggleSelected = (image: Image) => dispatch(toggleSelectedMedia({ selectionContext, media: image }));
  const setSelected = (image: Image) => {
    dispatch(ensureImagesLoaded(image.id));
    dispatch(setSelectedMedia({ selectionContext, media: [image] }));
  };

  const articles = image?.additionalArticles?.map(article => article.id).join(', ');
  const imageVersion = image?.versions[displayedVersion ?? ''];

  if (onClick != null && disableInteraction === false) {
    throw Error("Invalid combination of properties on ImageCard, can't set onClick unless disableInteraction is true");
  }

  const setImageFullScreen = () => {
    if (imageVersion) {
      dispatch(zoomImage({ image: imageVersion }));
    }
  };
  const toggleEnlarged = () => {
    setEnlarged(e => !e);
    setTimeout(() => cardRef.current?.scrollIntoView({ behavior: 'smooth' }));
  };

  const onClickImage = (e: React.MouseEvent<HTMLElement>) =>
    e.shiftKey || e.metaKey || e.ctrlKey ? toggleSelected(image!) : setSelected(image!);

  const onImageClicked = (e: React.MouseEvent<HTMLElement>) => {
    if (enlarged) {
      setEnlarged(false);
      setTimeout(() => cardRef.current?.scrollIntoView({ behavior: 'smooth' }));
    } else {
      onClickImage?.(e);
    }
  };

  const actionButtons = [
    <ImageCardDownload imageVersion={imageVersion} />,
    <ImageCardZoomButton isEnlarged={enlarged} toggleEnlarged={toggleEnlarged} setFullScreen={setImageFullScreen} />,
    <RemoveImageButton image={image} canRemove={!readonly && !disableRemoval} onRemove={onRemove} />,
  ];

  const getThumbnailUri = (size: ThumbnailImageSize) => GetThumbnailUri(imageVersion, size);
  const hasThumbnail = (size: ThumbnailImageSize) => HasThumbnail(imageVersion, size);

  const shouldDisplaySpinner = image != null && !hasThumbnail(320);

  const onDrop: DropzoneCallback = (files, onUploadProgress) => {
    setFiles(files);
    setOnUploadProgress({ handler: onUploadProgress });
    setDropFileActionDialogVisible(true);
  };

  const hasDropFileActions = canReplaceImage || canCreateVariant;

  const thumbnailWrapper = (cardMedia: JSX.Element) =>
    !hasDropFileActions || caze == null ? (
      <MediaWithPlayIcon cardMedia={cardMedia} imageVersion={imageVersion} disablePlayIcon={enlarged} />
    ) : (
      <DropzoneWithProgress onDrop={onDrop} singleFile={!enableVariantCreation} noClick fileUploadInProgress={fileUploadInProgress}>
        <MediaWithPlayIcon cardMedia={cardMedia} imageVersion={imageVersion} disablePlayIcon={enlarged} />
      </DropzoneWithProgress>
    );

  const classes = [];
  if (className != null) {
    classes.push(className);
  }
  const cardClasses = classes.join(' ');

  if (isSelected) {
    style = { ...style, outline: `2px solid ${palette.primary.main}` };
  }

  const closeDropFileActionDialog = () => {
    setFiles([]);
    setOnUploadProgress({});
    setDropFileActionDialogVisible(false);
  };

  if (enlarged) {
    style = { ...style, flexBasis: '100%' };
  }

  const imageSrc = (enlarged ? imageVersion?.uri : getThumbnailUri(320)) ?? PlaceholderUri;

  return (
    <>
      <MediaCard
        ref={cardRef}
        cardHeader={
          <ImageCardHeader
            image={image}
            onImageActiveVersionChange={onImageActiveVersionChange}
            displayedVersion={displayedVersion}
            disableInteraction={disableInteraction}
          />
        }
        shouldDisplaySpinner={shouldDisplaySpinner}
        image={imageVersion}
        imageThumbnailSrc={imageSrc}
        imageTitle={articles}
        thumbnailWrapper={thumbnailWrapper}
        cardBody={<ImageCardBody imageVersion={imageVersion} />}
        actions={additionalActions}
        additionalButtons={actionButtons}
        cardFooter={<ImageCardFooter image={image} />}
        className={cardClasses}
        style={style}
        raised={raised != null ? raised : isSelected}
        disableInteraction={disableInteraction}
        onClick={onClick}
        onClickImage={onImageClicked}
        large={enlarged}
      />
      {hasDropFileActions && (
        <DropFileActionDialog
          caze={caze}
          image={image}
          files={files}
          replaceMediaAction={replaceMediaAction}
          canCreateVariant={canCreateVariant || false}
          canReplaceImage={canReplaceImage || false}
          onUploadProgressHandler={onUploadProgress?.handler}
          visible={dropFileActionDialogVisible}
          setDropFileActionDialogVisible={setDropFileActionDialogVisible}
          onClose={closeDropFileActionDialog}
        />
      )}
    </>
  );
};
