import React from 'react';

import { Case, CaseType } from '../../../api/caseApiTypes';
import { ImageFloorFields, ImageTilingFields, ImageWallpaperFields } from '../formFieldComponents/GenericSupplierDesignFields';
import { ImageClassificationField } from '../formFieldComponents/ImageClassificationField';
import { ImagePaintFields } from '../formFieldComponents/ImagePaintFields';
import { makeMediaValue } from '../formFieldComponents/MediaValue';
import { ImagePhotographerField, ImageStylistField } from "../formFieldComponents/GenericEnumerationFields";

interface Props {
  item: Case;
  onChange: (partial: Partial<Case>) => void;
  readonly?: boolean;
  renderArticles?: boolean;
}

const PhotoTypes = [CaseType.ContextualPhoto, CaseType.InstallationPhoto, CaseType.Video];

export const CaseEnrichmentForm: React.FC<Props> = ({ item, onChange, readonly }) => {
  const isType = (type: CaseType) => item.type === type;
  const isPhotoType = PhotoTypes.some(isType);
  // PP#121: Stylist not used for dining set cases
  const showStylist = item.type !== CaseType.DiningSet;

  return (
    <>
      <ImageClassificationField
        imageClassification={makeMediaValue(item.imageClassification)}
        onChange={v => onChange({ imageClassification: v })}
        readonly={readonly}
      />
      <ImagePhotographerField
        photographer={makeMediaValue(item.photographer)}
        onChange={v => onChange({ photographer: v })}
        readonly={readonly}
      />
      {showStylist && (
        <ImageStylistField
          stylist={makeMediaValue(item.stylist)}
          onChange={v => onChange({ stylist: v })}
          readonly={readonly}
        />
      )}
      {isPhotoType && (
        <>
          <ImageWallpaperFields
            item={makeMediaValue(item.wallpaper)}
            onChange={wallpaperChange => onChange({ wallpaper: wallpaperChange(item.wallpaper) })}
            readonly={readonly}
          />
          <ImagePaintFields
            item={makeMediaValue(item.paint)}
            onChange={paintChange => onChange({ paint: paintChange(item.paint) })}
            readonly={readonly}
          />
          <ImageFloorFields
            item={makeMediaValue(item.floor)}
            onChange={floorChange => onChange({ floor: floorChange(item.floor) })}
            readonly={readonly}
          />
          <ImageTilingFields
            item={makeMediaValue(item.tiling)}
            onChange={tilingChange => onChange({ tiling: tilingChange(item.tiling) })}
            readonly={readonly}
          />
        </>
      )}
    </>
  );
};
