import React from 'react';

import { Article, Attachment, AttachmentCategory, Case } from '../../../api/caseApiTypes';
import { Right } from '../../../api/userApi';
import { CaseAttachments } from '../CaseAttachments';
import { CaseDescriptionField } from '../formFieldComponents/CaseDescriptionField';
import { CaseDetailEnrichmentSubForm } from './CaseDetailEnrichmentSubForm';
import { CaseArticles } from '../CaseArticles';
import { CaseFormSection } from './CaseFormSection';

interface Props {
  caze: Case;
  onChange: (partial: Partial<Case>) => void;
  onDeleteAttachment: (attachment: Attachment) => void;
  onUpsertArticle?: (article: Article) => void;
  onUpsertManyArticles?: (articles: Article[]) => void;
  onDeleteArticle?: (article: Article) => void;
  readonly?: boolean;
  isNewCase?: boolean;
  maxArticles: number | undefined;
}

export const SingleProductCaseDetailFormFields: React.FC<Props> = ({
  caze,
  onChange,
  onDeleteAttachment,
  onUpsertArticle,
  onUpsertManyArticles,
  onDeleteArticle,
  readonly,
  isNewCase,
  maxArticles,
}) => {
  return (
    <>
      <CaseFormSection title="Artikel" caseId={caze.id}>
        <CaseArticles
          articles={caze.articles}
          onUpsert={onUpsertArticle}
          onAddMany={onUpsertManyArticles}
          onDelete={onDeleteArticle}
          isNewCase={isNewCase}
          readonly={readonly}
          renderExtraInfo
          maxArticles={maxArticles}
        />
      </CaseFormSection>

      <CaseFormSection title="Beställningsinformation" caseId={caze.id}>
        <CaseDescriptionField description={caze.description} onChange={v => onChange({ description: v })} readonly={readonly} />
        <CaseAttachments
          caze={caze}
          onDelete={onDeleteAttachment}
          category={AttachmentCategory.SupplementaryInformation}
          right={Right.WriteCase}
          isNewCase={isNewCase}
        />
      </CaseFormSection>

      <CaseDetailEnrichmentSubForm caze={caze} onChange={onChange} />
    </>
  );
};
