import React, { useMemo } from 'react';

import { Article, Image } from '../../api/caseApiTypes';
import { ArticleChange, ImageArticles } from './ImageArticles';
import { CaseArticleIdField } from '../cases/formFieldComponents/CaseArticleIdField';
import { CaseProductNameField } from '../cases/formFieldComponents/CaseProductNameField';
import { CaseTextField } from '../cases/formFieldComponents/CaseTextField';
import { ImageFloorFields, ImageTilingFields, ImageWallpaperFields } from '../cases/formFieldComponents/GenericSupplierDesignFields';
import { ImageClassificationField } from '../cases/formFieldComponents/ImageClassificationField';
import { ImagePaintFields } from '../cases/formFieldComponents/ImagePaintFields';
import { ImageStateField } from '../cases/formFieldComponents/ImageStateField';
import { ImageTypeField } from '../cases/formFieldComponents/ImageTypeField';
import { ImageTags } from '../image/ImageTags';

import { ImageActivationDates, ImageDeadlineDates } from '../cases/formFieldComponents/ImageDateFieldsPair';
import { MediaChange, useBatchMediaEnrichment } from './useImageEnrichment';
import { CaseProductDescriptionField } from '../cases/formFieldComponents/CaseProductDescriptionField';
import { MediaValue, getMediaValue } from '../cases/formFieldComponents/MediaValue';
import { CaseRetouchByField, ImagePhotographerField, ImageStylistField } from '../cases/formFieldComponents/GenericEnumerationFields';
import { Stack, Box, Button } from '@mui/material';

import styles from './ImageEnrichmentForm.module.css';

interface Props {
  media: Image[];
  copyTagsFromCaseButton?: React.ReactNode;
  onChange: (change: MediaChange) => void;
  readonly?: boolean;
  renderArticles?: boolean;
}

export type EnrichmentMedia = { [key in keyof Image]: MediaValue<Image[key]>[] };

function addMediaValue<K extends keyof Image>(result: Partial<EnrichmentMedia>, media: Image, key: K) {
  const mediaValue: MediaValue<Image[K]> = {
    imageId: media.id,
    value: media[key],
  };
  if (result[key] == null) {
    result[key] = [];
  }
  result[key]?.push(mediaValue);
}

export function combineMediaForEnrichment(media: Image[]): EnrichmentMedia | null {
  if (media.length === 0) return null;

  const result: Partial<EnrichmentMedia> = {};

  for (const item of media) {
    const keys = Object.keys(item) as (keyof EnrichmentMedia)[];

    for (const key of keys) {
      addMediaValue(result, item, key);
    }
  }

  return result as EnrichmentMedia;
}

function combineArticlesForEnrichment(media: Image[]) {
  const articleLookup = new Map<string, { priority: number; article: Article; count: number }>();
  for (let mediaItem of media) {
    const itemArticles = mediaItem.additionalArticles;
    if (!itemArticles) continue;

    for (let i = 0; i < itemArticles.length; i++) {
      const article = itemArticles[i];
      let existingArticle = articleLookup.get(article.articleId);
      if (existingArticle) {
        existingArticle.count += 1;
        existingArticle.priority = Math.min(existingArticle.priority, i / itemArticles.length);
      } else {
        articleLookup.set(article.articleId, {
          article,
          count: 1,
          priority: i / itemArticles.length,
        });
      }
    }
  }

  function calcPriority(a: Article, b: Article) {
    const aPrio = articleLookup.get(a.articleId)?.priority ?? 0;
    const bPrio = articleLookup.get(b.articleId)?.priority ?? 0;
    const aName = a.productName ?? '';
    const bName = b.productName ?? '';
    return aPrio - bPrio || aName.localeCompare(bName);
  }

  const articles = Array.from(articleLookup.values()).map(({ article }) => article);
  articles.sort((a, b) => calcPriority(a, b));
  return articles;
}

export const ImageEnrichmentFormFields: React.FC<Props> = ({ media, onChange, copyTagsFromCaseButton, readonly }) => {
  const image = useMemo(() => combineMediaForEnrichment(media), [media]);
  const articles = useMemo(() => combineArticlesForEnrichment(media), [media]);

  const onChangeArticle = (articleChange: ArticleChange) =>
    onChange((change, update) => {
      const articles = update?.additionalArticles || change?.additionalArticles || [];
      return { additionalArticles: articleChange(articles) };
    });

  const onChangeMedia = (partial: Partial<Image>) => onChange(() => partial);

  if (!image) return <></>;

  return (
    <>
      <ImageTags media={media} copyTagsFromCaseButton={copyTagsFromCaseButton} readonly={readonly} size={'small'} />
      <CaseProductNameField productName={image.productName} onChange={v => onChangeMedia({ productName: v })} readonly={readonly} />
      <CaseArticleIdField articleId={image.articleId} onChange={v => onChangeMedia({ articleId: v })} readonly={readonly} />
      <CaseProductDescriptionField
        description={image.productDescription}
        label="Produktbeskrivning"
        minRows={3}
        onChange={v => onChangeMedia({ productDescription: v })}
        readonly={readonly}
      />
      <ImageTypeField imageType={image.type} onChange={v => onChangeMedia({ type: v })} readonly={readonly} />
      <ImageClassificationField
        imageClassification={image.classification}
        onChange={v => onChangeMedia({ classification: v })}
        readonly={readonly}
      />
      <ImageStateField imageState={image.state} onChange={v => onChangeMedia({ state: v })} readonly={readonly} />
      <ImageActivationDates item={image} onChange={onChangeMedia} readonly={readonly} />
      <ImageDeadlineDates item={image} onChange={onChangeMedia} readonly={readonly} />
      <ImagePhotographerField photographer={image.photographer} onChange={v => onChangeMedia({ photographer: v })} readonly={readonly} />
      <ImageStylistField stylist={image.stylist} onChange={v => onChangeMedia({ stylist: v })} readonly={readonly} />
      <CaseRetouchByField editor={image.retouchedBy} onChange={v => onChangeMedia({ retouchedBy: v })} readonly={readonly} />
      <CaseTextField
        property="retouchChanges"
        label="Retuschkommentar"
        value={getMediaValue(image.retouchChanges, '', 'Blandade värden')}
        setValue={v => onChangeMedia({ retouchChanges: v })}
        minRows={3}
        readonly={readonly}
      />
      <ImageArticles articles={articles} maxArticles={undefined} noun="artiklar" readonly={readonly} onChange={onChangeArticle} />
      <ImageWallpaperFields
        item={image.wallpaper}
        onChange={wallpaperChange =>
          onChange((base, updates) => ({ wallpaper: wallpaperChange({ ...base.wallpaper, ...updates?.wallpaper }) }))
        }
        readonly={readonly}
      />
      <ImagePaintFields
        item={image.paint}
        onChange={paintChange => onChange((base, updates) => ({ paint: paintChange({ ...base.paint, ...updates?.paint }) }))}
        readonly={readonly}
      />
      <ImageFloorFields
        item={image.floor}
        onChange={floorChange => onChange((base, updates) => ({ floor: floorChange({ ...base.floor, ...updates?.floor }) }))}
        readonly={readonly}
      />
      <ImageTilingFields
        item={image.tiling}
        onChange={tilingChange => onChange((base, updates) => ({ tiling: tilingChange({ ...base.tiling, ...updates?.tiling }) }))}
        readonly={readonly}
      />
    </>
  );
};

interface FullPageProps {
  media: Image[];
  onCancelAndConfirm?: () => void;
}

export const ImageEnrichmentForm: React.FC<FullPageProps> = ({ media, onCancelAndConfirm }) => {
  const { enrichedImages, saveDisabled, onMediaPartialChange, persistChanges, cancelChanges, hasActiveChanges } =
    useBatchMediaEnrichment(media);

  if (media.length === 0) {
    return <></>;
  }

  const saveEnrichment = () => {
    persistChanges();
    onCancelAndConfirm?.();
  };

  const cancelEnrichment = () => {
    cancelChanges();
    onCancelAndConfirm?.();
  };

  const saveText = `Spara ${enrichedImages.length} bild${enrichedImages.length > 1 ? 'er' : ''}`;
  return (
    <Stack direction="column" spacing={2} sx={{ paddingLeft: '12px', paddingRight: '12px' }}>
      <ImageEnrichmentFormFields media={enrichedImages} onChange={onMediaPartialChange} />
      <Box className={styles.formButtons}>
        <Button
          variant="contained"
          style={{ visibility: 'hidden' }} /* This exist to balance the button row and place the save button in center */
        >
          Avbryt
        </Button>
        <Button variant="contained" disabled={saveDisabled} onClick={saveEnrichment}>
          {saveText}
        </Button>
        <Button variant="contained" color={'secondary'} disabled={!hasActiveChanges} onClick={cancelEnrichment}>
          Avbryt
        </Button>
      </Box>
    </Stack>
  );
};
