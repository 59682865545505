import { PropsWithChildren, useCallback } from 'react';
import styles from './CaseFormSection.module.css';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography, useTheme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useAppSelector } from '../../../hooks/useAppRedux';
import { selectShowCaseDetailSection, setShowCaseDetailSection } from '../../localUserStateSlice';
import { useDispatch } from 'react-redux';

export type CaseFormSectionProps = {
  caseId: string;
  title: string;
  description?: string;
  titleComponent?: JSX.Element;
};

export function CaseFormSection({ caseId, title, children, description, titleComponent }: PropsWithChildren<CaseFormSectionProps>) {
  const dispatch = useDispatch();
  let show = useAppSelector(selectShowCaseDetailSection(caseId, title));
  if (show === undefined) {
    show = true;
  }

  const toggleExpanded = useCallback(
    (event: React.SyntheticEvent, isExpanded: boolean) =>
      dispatch(setShowCaseDetailSection({ caseId: caseId, section: title, show: isExpanded })),
    [caseId, title, dispatch],
  );

  const { palette } = useTheme();

  const backgroundColor = palette.mode === 'light' ? '#eee' : '#444';

  return (
    <>
      <Accordion className={styles.accordion} expanded={show} onChange={toggleExpanded}>
        <AccordionSummary
          className={styles.accordionSummary}
          style={{
            backgroundColor: backgroundColor,
          }}
          expandIcon={<ExpandMore />}
          sx={{
            '& .MuiAccordionSummary-content': {
              overflow: 'hidden',
            },
          }}
        >
          <Typography className={styles.caseSectionTitle}>{title}</Typography>
          <Tooltip title={description} placement="bottom">
            <Typography className={styles.caseSectionDescription}>{description}</Typography>
          </Tooltip>
          <div className={styles.titleComponent}>{titleComponent}</div>
        </AccordionSummary>
        <AccordionDetails className={styles.caseChildren}>{children}</AccordionDetails>
      </Accordion>
    </>
  );
}
