import { CaseType, StateEnum } from '../../api/caseApiTypes';
import { FilterableCase } from '../../api/filterTypes';
import { SearchContext } from '../../hooks/useSearchState';
import { CasePropClauseResolverConfig } from '../sidebar/filter/propClauseResolver/resolverConfigs';
import { SearchFilterTranslator } from '../sidebar/filter/searchFilterTranslator';
import { CaseKanbanBoard } from './CaseKanbanBoard';
import { ProductContextualLaneDefinitions } from './ProductContextualLaneDefinitions';

export const ProductContextualKanbanSupportedTypes = [CaseType.ProductContextual];

const translator = new SearchFilterTranslator<FilterableCase>(CasePropClauseResolverConfig);

export const ProductContextualKanbanFilters = {
  userFilters: {
    type: { value: ProductContextualKanbanSupportedTypes.map(state => state.toString()) },
    Case_CaseState: {
      value: [StateEnum.ordered, StateEnum.planned, StateEnum.reviewPending].map(state => state.toString()),
    },
  },
};

const filterTree = translator.getClauses(ProductContextualKanbanFilters);

export const ProductContextualCaseKanbanPlan = () =>
  CaseKanbanBoard({
    filters: filterTree,
    lanes: ProductContextualLaneDefinitions,
    searchContext: SearchContext.PlanningProductContextualKanban,
  });
