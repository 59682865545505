import React from 'react';

import { Box, Divider, Link, Paper, Tooltip, Typography } from '@mui/material';

import styles from './CaseKanbanCard.module.css';

import { Case, CaseTypeConverter } from '../../api/caseApiTypes';
import { ArticleIdsList } from '../articles/ArticleIdsList';
import { CaseContainer } from '../cases/layoutComponents/CaseContainer';

interface Props {
  caze: Case;
}

export const CaseKanbanCard: React.FC<Props> = ({ caze }) => {
  const primaryArticle = caze.articles?.[0];
  const renderDivider = primaryArticle?.productDescription && caze.description;

  return (
    <Link className={styles.link} href={`/case/${caze.lookupEntry ?? caze.id}`} target="_blank" rel="noopener noreferrer" underline="none">
      <CaseContainer component={Paper} className={styles.card} type={caze.type}>
        <Box className={styles.cardHeader}>
          <Typography variant="caption">{CaseTypeConverter.ToName(caze.type)}</Typography>
          <ArticleIdsList item={caze} />
        </Box>
        <Typography variant="subtitle2">{primaryArticle?.productName}</Typography>
        <Box className={styles.mainDescription}>
          <Tooltip title={primaryArticle?.productDescription} enterDelay={500} followCursor>
            <Typography variant="body2">{primaryArticle?.productDescription}</Typography>
          </Tooltip>
        </Box>
        {renderDivider && <Divider sx={{ mt: '0.25em', mb: '0.25em' }} />}
        <Box className={styles.secondaryDescription}>
          <Tooltip title={caze?.description} enterDelay={500} followCursor>
            <Typography variant="body2">{caze?.description}</Typography>
          </Tooltip>
        </Box>
      </CaseContainer>
    </Link>
  );
};
